import { ROUTE_NAV } from '@/shared/config'
import { cookiesAccess } from '@/shared/lib/cookiesAccess'

import { authEntity } from '@/entities/auth'
import { userEntity } from '@/entities/user'

export function useAuthAutoLogin() {
  const router = useRouter()
  const localePath = useLocalePath()
  const $modal = useModal()
  const { $analytics } = useNuxtApp()

  return async (analyticsShouldBeEnabled: boolean = false) => {
    const reponseValid = await authEntity.model.tokenIsValid()
    if (!reponseValid) {
      return false
    }

    const meResult = await userEntity.model.meLoad()
    if (!meResult) {
      $analytics.event('login_auto', {
        type: 'error',
        error: 'user me not load',
      })
      return false
    }

    if (userEntity.model.isPaid() && analyticsShouldBeEnabled) {
      $modal?.close('cookies-access-tollbar')
      $analytics.init()
      cookiesAccess.acceptAll()
    }

    $analytics.event('login_auto', {
      type: 'success',
    })
    router.replace(localePath(ROUTE_NAV.cabMain))
    return true
  }
}
